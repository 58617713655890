<!--
 * @Author: zhangminxiang
 * @Descripttion: 建议表
 * @version: 1.0
 * @Date: 2022-03-11 18:44:08
 * @LastEditors: zhangminxiang
 * @LastEditTime: 2022-03-14 11:07:27
-->
<template>
 <div class="advice-table">
    <div class="actice-table-btn" @click="printBtn">
      <el-button type="primary" style="background:#156ED0;">打印</el-button>
    </div>
    <div class="advice-table-print" ref="print">
      <div class="advice-table-header flex col">
          <h1>{{mainInfo.entityName}}申报扶持项目建议表</h1>
          <h4>【{{mainInfo.orgName}}|{{mainInfo.date}}|联系人：{{mainInfo.principalName || '未指定'}}|电话：{{mainInfo.principalPhone}}】</h4>
      </div>
      <div class="advice-table-content">
        <el-table
          :data="tableData"
          border
          style="width: 100%">
          <el-table-column type="index" label="序号" width="60px" align="center"></el-table-column>
          <el-table-column prop="subtitle" label="项目简称" width="120px" align="center"></el-table-column>
          <el-table-column prop="zcJibie" label="申报层级" width="80px" align="center"></el-table-column>
          <el-table-column label="申报日期" width="120px" align="center">
            <template slot-scope="scope">
              {{scope.row.startDate}}至{{scope.row.endDate}}
            </template>
          </el-table-column>
          <el-table-column label="主要申报条件" align="center">
            <template slot-scope="scope">
              <div style="text-align:left;" v-for="(item, index) in scope.row.conditions" :key="index">{{item}}</div>
            </template>
          </el-table-column>
          <el-table-column label="扶持方式" width="120px" align="center">
            <template  slot-scope="scope">
              <div v-for="(item, index) in scope.row.formalSupport" :key="index">{{item}}</div>
            </template>
          </el-table-column>
          <el-table-column label="扶持标准" prop="subtitleDescribe" align="center" width="200px"></el-table-column>
          <el-table-column label="工作建议" width="220px" align="center"></el-table-column>
          <el-table-column label="政策依据" width="220px" align="center">
            <template slot-scope="scope">
              <div :id="`'qrcode'${scope.$index}`" ref="qrcode" class="flex"></div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
 </div>
</template>

<script>
import { request } from '@/network/index.js';
import QRCode from 'qrcodejs2';
export default {
  name: 'adviceTable',
  data () {
    return {
      tableData: [],
      mainInfo: {}
    };
  },
  mounted () {
    this.getInfo();
  },
  methods: {
    // 获取详细信息
    async getInfo () {
      const { data: res } = await request({
        method: 'get',
        url: '/pcp/org/getMatchDetail',
        params: {
          isPrint: 1,
          pushNo: this.$route.query.pushNo
        }
      });
      if (parseInt(res.code) === 200 && res.data) {
        this.tableData = res.data.matchList;
        this.mainInfo = res.data.title;
        for (const i in res.data.matchList) {
          this.$nextTick(() => {
            const qrcode = new QRCode(`'qrcode'${i}`, {
              width: 150,
              height: 150 // 高度
            // text: res.data.matchList[i].redirectUrl, // 二维码内容
            // correctLevel: QRCode.CorrectLevel.L,
            // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
            // background: '#f0f'
            // foreground: '#ff0'
            });
            qrcode.makeCode(res.data.matchList[i].redirectUrl);
          });
        }
      }
    },
    // 调用打印
    printBtn () {
      this.$print(this.$refs.print);
    }
  }
};
</script>

<style scoped lang = "less">
.advice-table{
  height: 100%;
  box-sizing: border-box;
  padding: 20px 40px;
  background-color: #fff;
  @media print {
    .actice-table-btn{
      display: none;
    }
  }
  .actice-table-btn{
    z-index: 1;
    position: fixed;
    top: 8%;
    right: 3%;
    opacity: .8;
  }
  .advice-table-print{
    .advice-table-header{
      width: 100%;
      padding-bottom: 84px;
      h1{
        padding-bottom: 18px;
      }
    }
    .advice-table-content{
      width: 100%;
      min-height: 800px;
    }
  }
}
</style>

<style>
.advice-table .el-table__header-wrapper  .el-table__cell {
  background-color: rgb(255,242,204);
}

.advice-table .el-table{
  border: 1px solid rgb(0, 0, 0);
}

.advice-table .el-table .el-table__cell{
  border-left: 1px solid rgb(0, 0, 0);
}
.advice-table .el-table .el-table__cell:nth-child(1){
  border-left: 0;
}

.advice-table .el-table .cell{
  color: #000;
}

.advice-table .el-table .el-table__body-wrapper{
  border-top: 1px solid rgb(0, 0, 0);
  height: 100%;
}

.advice-table .el-table .el-table__body{
  height: 100%;
}
</style>
